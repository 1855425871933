import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { BarStackHorizontalSimple, PatternLines, PatternCircles } from "@urbaninfrastructure/charts";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "barstackhorizontalsimple"
    }}>{`BarStackHorizontalSimple`}</h1>
    <p>{`Used for simple, one dimensional views of data.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {
  BarStackHorizontalSimple,
  PatternLines,
  PatternCircles
} from "@urbaninfrastructure/charts";

const App = () => (
  <>
    <svg width={0} height={0}>
      <PatternLines id="pattern-1" stroke="red" />
      <PatternLines id="pattern-2" stroke="green" />
    </svg>
    <BarStackHorizontalSimple
      height={20}
      data={[
        {
          key: "Austin",
          value: 72.2,
          fill: "blue"
        },
        {
          key: "New York",
          value: 63.4,
          fill: "url('#pattern-1')"
        },
        {
          key: "San Francisco",
          value: 62.7,
          fill: "url('#pattern-2')"
        }
      ]}
    />
  </>
);
`}</code></pre>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<div>\n  <svg width={0} height={0}>\n    <PatternLines id=\"pattern-1\" stroke=\"red\" />\n    <PatternLines id=\"pattern-2\" stroke=\"green\" />\n  </svg>\n  <BarStackHorizontalSimple\n    height={20}\n    width={200}\n    data={[\n      {\n        key: \'Austin\',\n        value: 72.2,\n        fill: \'blue\',\n      },\n      {\n        key: \'New York\',\n        value: 63.4,\n        fill: \"url(\'#pattern-1\')\",\n      },\n      {\n        key: \'San Francisco\',\n        value: 62.7,\n        fill: \"url(\'#pattern-2\')\",\n      },\n    ]}\n  />\n  <BarStackHorizontalSimple\n    height={20}\n    width={200}\n    data={[\n      {\n        key: \'Austin\',\n        value: 62.2,\n        fill: \'blue\',\n      },\n      {\n        key: \'New York\',\n        value: 83.4,\n        fill: \"url(\'#pattern-1\')\",\n      },\n      {\n        key: \'San Francisco\',\n        value: 22.7,\n        fill: \"url(\'#pattern-2\')\",\n      },\n    ]}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      BarStackHorizontalSimple,
      PatternLines,
      PatternCircles,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <svg width={0} height={0}>
      <PatternLines id="pattern-1" stroke="red" mdxType="PatternLines" />
      <PatternLines id="pattern-2" stroke="green" mdxType="PatternLines" />
    </svg>
    <BarStackHorizontalSimple height={20} width={200} data={[{
          key: "Austin",
          value: 72.2,
          fill: "blue"
        }, {
          key: "New York",
          value: 63.4,
          fill: "url('#pattern-1')"
        }, {
          key: "San Francisco",
          value: 62.7,
          fill: "url('#pattern-2')"
        }]} mdxType="BarStackHorizontalSimple" />
    <BarStackHorizontalSimple height={20} width={200} data={[{
          key: "Austin",
          value: 62.2,
          fill: "blue"
        }, {
          key: "New York",
          value: 83.4,
          fill: "url('#pattern-1')"
        }, {
          key: "San Francisco",
          value: 22.7,
          fill: "url('#pattern-2')"
        }]} mdxType="BarStackHorizontalSimple" />
  </div>
    </Playground>
    <h2 {...{
      "id": "with-inline-labels"
    }}>{`With inline labels`}</h2>
    <Playground __position={1} __code={'<div>\n  <svg width={0} height={0}>\n    <PatternLines id=\"pattern-1\" stroke=\"red\" />\n    <PatternLines id=\"pattern-2\" stroke=\"green\" />\n  </svg>\n  <BarStackHorizontalSimple\n    height={20}\n    width={200}\n    barText={({ index }) => {\n      return {\n        children: `index`,\n        style: {\n          color: \'white\',\n          textShadow: \'0 0 2px rgba(0,0,0,.8)\',\n        },\n        fill: \'white\',\n        scaleToFit: true,\n      }\n    }}\n    data={[\n      {\n        key: \'Austin\',\n        value: 72.2,\n        fill: \'blue\',\n      },\n      {\n        key: \'New York\',\n        value: 63.4,\n        fill: \"url(\'#pattern-1\')\",\n      },\n      {\n        key: \'San Francisco\',\n        value: 62.7,\n        fill: \"url(\'#pattern-2\')\",\n      },\n    ]}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      BarStackHorizontalSimple,
      PatternLines,
      PatternCircles,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <svg width={0} height={0}>
      <PatternLines id="pattern-1" stroke="red" mdxType="PatternLines" />
      <PatternLines id="pattern-2" stroke="green" mdxType="PatternLines" />
    </svg>
    <BarStackHorizontalSimple height={20} width={200} barText={({
          index
        }) => {
          return {
            children: `index`,
            style: {
              color: "white",
              textShadow: "0 0 2px rgba(0,0,0,.8)"
            },
            fill: "white",
            scaleToFit: true
          };
        }} data={[{
          key: "Austin",
          value: 72.2,
          fill: "blue"
        }, {
          key: "New York",
          value: 63.4,
          fill: "url('#pattern-1')"
        }, {
          key: "San Francisco",
          value: 62.7,
          fill: "url('#pattern-2')"
        }]} mdxType="BarStackHorizontalSimple" />
  </div>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={BarStackHorizontalSimple} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      